import React from 'react';
import Header from './Header';
import backgroundImage from '../img/CAPA.gif';
import {
  FaReact,
  FaNodeJs,
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaPhp,
  FaDatabase,
  FaLeaf,
  FaBootstrap
} from 'react-icons/fa';

function Tecnologias() {
  const technologies = [
    { name: 'React', icon: <FaReact className="text-gray-500" />, description: 'Desenvolvimento de interfaces modernas e dinâmicas.' },
    { name: 'Node.js', icon: <FaNodeJs className="text-gray-500" />, description: 'Aplicações backend escaláveis e eficientes.' },
    { name: 'HTML5', icon: <FaHtml5 className="text-gray-500" />, description: 'Estruturação de páginas web com semântica avançada.' },
    { name: 'CSS3', icon: <FaCss3Alt className="text-gray-500" />, description: 'Estilização avançada e responsiva.' },
    { name: 'JavaScript', icon: <FaJs className="text-gray-500" />, description: 'Programação dinâmica e interativa no frontend.' },
    { name: 'PHP', icon: <FaPhp className="text-gray-500" />, description: 'Desenvolvimento de sistemas web robustos.' },
    { name: 'MongoDB', icon: <FaLeaf className="text-gray-500" />, description: 'Banco de dados NoSQL escalável.' },
    { name: 'MariaDB', icon: <FaDatabase className="text-gray-500" />, description: 'Banco de dados SQL robusto e open-source.' },
    { name: 'Tailwind CSS', icon: <FaCss3Alt className="text-gray-500" />, description: 'Framework de CSS utilitário para criar layouts rápidos.' },
    { name: 'Bootstrap', icon: <FaBootstrap className="text-gray-500" />, description: 'Framework front-end responsivo para desenvolvimento rápido.' },
  ];

  return (
    <>
      <Header />
      {/* Capa com ajuste para evitar sobreposição do cabeçalho */}
      <section
        className="relative bg-cover bg-center text-gray-800 pt-24"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container mx-auto text-center py-16 bg-gray-900 bg-opacity-60 rounded-lg">
          <h1 className="text-3xl font-bold text-white">As Tecnologias que Usamos</h1>
          <p className="text-lg mt-4 text-gray-200">Construímos soluções usando as melhores e mais modernas tecnologias.</p>
        </div>
      </section>

      {/* Seção de Tecnologias */}
      <section className="bg-gray-50 py-16 mt-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">Tecnologias que Usamos</h2>
          <p className="text-center text-lg text-gray-600 mb-12">Construímos soluções usando as melhores e mais modernas tecnologias do mercado.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {technologies.map((tech, index) => (
              <div 
                key={index} 
                className="relative bg-white p-6 rounded-lg border border-gray-300 text-center transition duration-300"
                style={{ borderRadius: '10px' }}
              >
                <div className="text-6xl mb-4">
                  {tech.icon}
                </div>
                <h3 className="text-2xl font-semibold text-gray-700 mb-2">{tech.name}</h3>
                <p className="text-gray-500">{tech.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Tecnologias;
