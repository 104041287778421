import React, { useEffect } from 'react';
import { FaSearch, FaBullseye, FaLightbulb, FaCode, FaClipboardCheck, FaHandshake } from 'react-icons/fa';
import rocketImage from '../img/metodologia-desenvolvimento.png';

function DevelopmentProcess() {
  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card, index) => {
      card.style.animationDelay = `${index * 0.2}s`;
      card.classList.add('animate-slide-up');
    });
  }, []);

  return (
    <section className="py-20 bg-gray-50 text-gray-900 relative">
      <div className="container mx-auto text-center px-6 md:px-0">
        <h2 className="text-3xl font-bold mb-16">Nosso Processo de Desenvolvimento</h2>

        {/* Layout ajustável para mobile e desktop */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-items-center">
          
          {/* Primeiros 3 Itens */}
          <div className="flex flex-col space-y-8 text-left md:col-start-1 md:col-span-1 px-4">
            <div className="flex items-start">
              <div className="bg-blue-100 p-3 rounded-full mr-4">
                <FaSearch className="text-gray-500 text-2xl md:text-3xl" />
              </div>
              <div>
                <h3 className="text-lg md:text-2xl font-semibold">Análise</h3>
                <p className="text-sm md:text-lg text-gray-700">Junto à equipe que recebe o novo projeto, criam uma lista inicial com os requisitos do sistema.</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="bg-purple-100 p-3 rounded-full mr-4">
                <FaLightbulb className="text-gray-500 text-2xl md:text-3xl" />
              </div>
              <div>
                <h3 className="text-lg md:text-2xl font-semibold">Solução</h3>
                <p className="text-sm md:text-lg text-gray-700">O desenvolvimento da solução é iniciado pela equipe para a entrega da sprint.</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="bg-yellow-100 p-3 rounded-full mr-4">
                <FaClipboardCheck className="text-gray-500 text-2xl md:text-3xl" />
              </div>
              <div>
                <h3 className="text-lg md:text-2xl font-semibold">Revisão</h3>
                <p className="text-sm md:text-lg text-gray-700">A equipe realiza uma retrospectiva para avaliar pontos bons e ruins.</p>
              </div>
            </div>
          </div>

          {/* Imagem Central */}
          <div className="flex flex-col items-center my-8 md:my-0">
            <img src={rocketImage} alt="Rocket" className="w-24 md:w-40 opacity-75" />
          </div>

          {/* Últimos 3 Itens */}
          <div className="flex flex-col space-y-8 text-left md:col-start-3 md:col-span-1 px-4">
            <div className="flex items-start">
              <div className="bg-green-100 p-3 rounded-full mr-4">
                <FaBullseye className="text-gray-500 text-2xl md:text-3xl" />
              </div>
              <div>
                <h3 className="text-lg md:text-2xl font-semibold">Objetivo</h3>
                <p className="text-sm md:text-lg text-gray-700">A equipe realiza uma reunião onde dividem tarefas para a entrega da sprint.</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="bg-red-100 p-3 rounded-full mr-4">
                <FaCode className="text-gray-500 text-2xl md:text-3xl" />
              </div>
              <div>
                <h3 className="text-lg md:text-2xl font-semibold">Desenvolvimento</h3>
                <p className="text-sm md:text-lg text-gray-700">A entrega da sprint é realizada com desenvolvimento e testes.</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="bg-teal-100 p-3 rounded-full mr-4">
                <FaHandshake className="text-gray-500 text-2xl md:text-3xl" />
              </div>
              <div>
                <h3 className="text-lg md:text-2xl font-semibold">Entrega</h3>
                <p className="text-sm md:text-lg text-gray-700">Entregamos o projeto ao cliente com qualidade e dedicação.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DevelopmentProcess;
