import React from 'react';
import { FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-800 text-gray-300 py-8 w-full mt-auto">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        <div className="flex flex-col md:flex-row justify-between items-center border-b border-gray-600 pb-6 mb-6">
          {/* Seção à esquerda com CNPJ e informações da empresa */}
          <div className="text-center md:text-left md:w-1/2">
            <h3 className="text-lg font-semibold text-gray-100">&copy; 2024 Vertex Systems and Software Development</h3>
            <p className="mt-2 text-sm text-gray-400">Todos os direitos reservados.</p>
            <p className="mt-1 text-sm text-gray-400">CNPJ: 56.996.099/0001-88</p>
          </div>

          {/* Ícone do Instagram centralizado */}
          <div className="mt-6 md:mt-0">
            <a href="https://www.instagram.com/vanrley.macedo/" className="hover:text-white transition-colors flex items-center">
              <FaInstagram size={20} className="mr-2 text-gray-400 hover:text-pink-500 transition-colors" />
              <span className="text-sm text-gray-400">Siga-nos no Instagram</span>
            </a>
          </div>
        </div>

        {/* Linha com marca da empresa no final */}
        <div className="text-center text-sm text-gray-400">
          <p>Vertex Systems</p>
          <p>Soluções de Desenvolvimento de Software</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
