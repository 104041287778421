import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter'; // Importando o Typewriter

function HeroSection() {
  return (
    <section
      className="relative bg-cover bg-center text-white mb-20"
      style={{
        backgroundImage: `url('https://cdn.svgator.com/images/2022/06/use-svg-as-background-image-particle-strokes.svg')`,
        height: '500px'
      }}
    >
      {/* Overlay escuro */}
      <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-70"></div>

      <div className="container relative mx-auto h-full flex flex-col justify-end items-center px-6 text-center z-10 pb-16">
        {/* Título com animação de digitação */}
        <h1 className="text-3xl font-bold mb-4 leading-snug">
          <Typewriter
            words={[
              'Transforme sua empresa com um site de impacto 🚀',
              'Desenvolvimento de sistemas personalizados 💼',
              'Inove com soluções digitais avançadas 💻',
              'Potencialize seus resultados com tecnologia 📈'
            ]}
            loop={true}
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={2000}
          />
        </h1>

        {/* Descrição */}
        <p className="text-base mb-6 max-w-md">
          Tenha ao seu lado nossa experiência em desenvolvimento digital para criar o site ou sistema ideal para o seu negócio.
        </p>

        {/* Botão de Ação estilizado */}
        <Link 
          to="/fale-conosco" 
          className="flex items-center px-8 py-2 border-2 border-white text-white text-base font-semibold rounded-lg hover:bg-white hover:text-blue-600 transition duration-300"
        >
          <span className="mr-2">Entre em Contato</span>
          <FaArrowRight />
        </Link>
      </div>
    </section>
  );
}


export default HeroSection;
