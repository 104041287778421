import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import backgroundImage from '../img/CAPA.gif';
import project1 from '../img/Macbook-Air-teste.sttarlink.com.br.png';
import project2 from '../img/Macbook-Air-cardapio.sttarlink.com.br.png';
import project3 from '../img/Macbook-Air-assinaturamaxx.netlify.app.png';
import project4 from '../img/Macbook-Air-lock.sttarlink.com.br.png'; // Adicione a imagem do projeto LockGuard

function Projects() {
  const projects = [
    {
      id: 1,
      title: 'Atlas Almoxarifado e Controle de Estoque',
      description: 'Desenvolvimento de um sistema completo de controle de estoque e almoxarifado para diversas categorias de produtos.',
      image: project1,
    },
    {
      id: 2,
      title: 'Sistema de Cardápio para Multi Lojas',
      description: 'Sistema de gerenciamento de cardápios e pedidos para múltiplas lojas, com controle unificado e relatórios.',
      image: project2,
    },
    {
      id: 3,
      title: 'Sistema de Assinaturas Corporativas de E-mail',
      description: 'Aplicação para gestão e criação de assinaturas corporativas personalizadas para e-mails empresariais.',
      image: project3,
    },
    {
      id: 4,
      title: 'LockGuard - Gerenciamento de Dados',
      description: 'Sistema avançado de gerenciamento de dados sensíveis com foco em segurança e integridade da informação.',
      image: project4, // Imagem do LockGuard
    },
  ];

  return (
    <>
      <Helmet>
        <title>Projetos - Vertex Systems</title>
        <meta name="description" content="Veja nossos principais projetos, incluindo sistemas de controle de estoque, cardápio, assinaturas corporativas e gerenciamento de dados." />
        <meta property="og:title" content="Nossos Projetos - Vertex Systems" />
        <meta property="og:description" content="Veja nossos principais projetos, incluindo sistemas de controle de estoque, cardápio, assinaturas corporativas e gerenciamento de dados." />
        <meta property="og:image" content={project1} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Header />
      <section
        className="relative bg-cover bg-center text-white pt-24"
        style={{ backgroundImage: `url(${backgroundImage})`, }} 
      >
        <div className="container mx-auto text-center py-16">
          <h1 className="text-4xl font-bold">Nossos Projetos</h1>
          <p className="text-lg mt-4">Veja alguns dos nossos projetos mais recentes e inovadores.</p>
        </div>
      </section>

      <section className=" py-16 mt-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects.map((project) => (
              <div key={project.id} className="bg-white border border-gray-300 rounded-lg overflow-hidden transition duration-300">
                <div className="relative overflow-hidden flex justify-center items-center">
                  <img src={project.image} alt={project.title} className="w-3/4 h-auto object-contain" />
                </div>
                <div className="p-6">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-2">{project.title}</h3>
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Projects;
