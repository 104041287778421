import React from 'react';
import vanrleyImage from '../img/vanrley.jpeg'; // Imagem de Vanrley Macedo

function QuemSouEu() {
  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Imagem de perfil */}
          <div className="w-full lg:w-1/3 flex justify-center lg:justify-start mb-8 lg:mb-0">
            <div className="relative">
              <img
                src={vanrleyImage}
                alt="Vanrley Macedo"
                className="shadow-lg shadow-gray-400 w-80 h-80 object-cover border-4 border-gray-300 transform hover:scale-105 transition duration-300 ease-in-out" // Tons de cinza
              />
            </div>
          </div>
          
          {/* Seção de Texto */}
          <div className="w-full lg:w-2/3 lg:pl-12 text-justify">
            <h2 className="text-3xl font-bold text-gray-700 mb-6">Vanrley Macedo</h2> {/* Nome em tom de cinza escuro */}
            <p className="text-lg text-gray-600 mb-6 leading-relaxed">
              Sou um desenvolvedor Full-Stack, apaixonado pelo mundo da tecnologia. Resido em São Luís e tenho uma vasta experiência em criar soluções digitais personalizadas e completas.
            </p>
            <p className="text-lg text-gray-600 leading-relaxed">
              Tenho especialização no desenvolvimento web e mobile, com foco em tecnologias modernas como React, Node.js, PHP e MySQL. Transformo ideias em soluções digitais robustas, eficientes e escaláveis.
            </p>
            <p className="text-xl text-gray-700 mt-6 font-bold">
              Quer discutir um projeto? <span className="text-gray-500 hover:underline cursor-pointer">Vamos conversar!</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuemSouEu;
