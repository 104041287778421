import React from 'react';
import capaImg from '../img/CAPA.gif'; // Imagem de capa

const certificates = [
  { id: 1, src: require('../certificados/programmer.png'), alt: 'Certificado 1' },
  { id: 2, src: require('../certificados/machine.png'), alt: 'Certificado 2' },
  { id: 3, src: require('../certificados/web.png'), alt: 'Certificado 3' },
  { id: 4, src: require('../certificados/produtividade.png'), alt: 'Certificado 4' },
  { id: 5, src: require('../certificados/ferramentas.png'), alt: 'Certificado 5' },
  { id: 6, src: require('../certificados/BACKEND.png'), alt: 'Certificado Backend' },
];

function Certificados() {
  // Função para bloquear o clique com o botão direito
  const disableRightClick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* Seção de Capa */}
      <section
        className="relative bg-cover bg-center text-center"
        style={{
          backgroundImage: `url(${capaImg})`,
          paddingTop: '10rem',
          paddingBottom: '3rem',
        }}
      >
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold text-white">Certificados</h1>
          <p className="text-lg mt-4 text-gray-200">
            Confira abaixo nossos certificados de qualificação e experiência.
          </p>
        </div>
      </section>

      {/* Seção de Certificados */}
      <section className="py-12 bg-gray-100" onContextMenu={disableRightClick}>
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Nossos Certificados</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {certificates.map((certificate, index) => (
              <div
                key={certificate.id}
                className={`relative p-4 bg-white shadow-md rounded-lg border border-gray-200 ${
                  certificate.alt === 'Certificado Backend' ? 'animate-glow border-yellow-500' : ''
                }`}
              >
                {/* Bolinha de contagem em cinza */}
                <div className="absolute top-2 left-2 w-8 h-8 rounded-full bg-gray-400 flex items-center justify-center text-white font-bold">
                  {index + 1}
                </div>

                {/* Selo especial para o certificado de Backend */}
                {certificate.alt === 'Certificado Backend' && (
                  <div className="absolute top-2 right-2 px-2 py-1 bg-yellow-500 text-xs text-white font-bold rounded-full shadow-md">
                    Backend
                  </div>
                )}

                {/* Imagem como background para ocultação */}
                <div
                  className="w-full h-64 bg-cover bg-center rounded-md mt-4"
                  style={{
                    backgroundImage: `url(${certificate.src})`,
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Certificados;
