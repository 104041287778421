import React from 'react';
import CountUp from 'react-countup';
import projectImage from '../img/PROJETO-SOBRE.png'; // Única imagem do projeto

function Highlights() {
  return (
    <section className="py-20 bg-gray-900 text-white">
      <div className="container mx-auto text-center">
        {/* Cabeçalho */}
        <h2 className="text-3xl font-bold mb-4">
          Enquanto eles discutem o "como", nós entregamos o resultado
        </h2>
        <p className="text-gray-300 text-lg mb-12">
          O que esperar de nós? Soluções completas e focadas na entrega, sem perder tempo com debates cansativos.
        </p>

        {/* Contadores */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {/* Anos de Experiência */}
          <div className="p-8 bg-gray-800 shadow-md rounded-lg">
            <div className="flex flex-col items-center">
              <h3 className="text-5xl font-bold text-white mb-2">
                <CountUp end={4} duration={2} />+
              </h3>
              <p className="text-gray-300 text-lg">Anos de experiência</p>
            </div>
          </div>

          {/* Novos Clientes */}
          <div className="p-8 bg-gray-800 shadow-md rounded-lg">
            <div className="flex flex-col items-center">
              <h3 className="text-5xl font-bold text-white mb-2">
                <CountUp end={78} duration={2} />+
              </h3>
              <p className="text-gray-300 text-lg">Clientes Atendidos</p>
            </div>
          </div>

          {/* Projetos Concluídos */}
          <div className="p-8 bg-gray-800 shadow-md rounded-lg">
            <div className="flex flex-col items-center">
              <h3 className="text-5xl font-bold text-white mb-2">
                <CountUp end={120} duration={2} />+
              </h3>
              <p className="text-gray-300 text-lg">Projetos Concluídos</p>
            </div>
          </div>
        </div>

        {/* Imagem Principal */}
        <div className="flex justify-center">
          <img
            src={projectImage}
            alt="Projeto"
            className="w-full md:w-2/3 lg:w-1/2 rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
}

export default Highlights;
