// components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa'; // Ícone de Home

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white text-center px-4">
      {/* Título 404 com cor laranja */}
      <h1 className="text-9xl font-extrabold text-orange-500 mb-6 animate-pulse">404</h1>
      
      {/* Mensagem de erro com estilo */}
      <p className="text-3xl text-gray-700 mb-4 font-semibold">
        Oops! Parece que a página que você procurava não existe.
      </p>
      <p className="text-lg text-gray-600 mb-8">
        Não se preocupe, isso acontece até nas melhores famílias. Vamos te guiar de volta!
      </p>
      
      {/* Botão estilizado para voltar à página inicial */}
      <Link 
        to="/" 
        className="flex items-center bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
      >
        <FaHome className="mr-2" /> Voltar para a Home
      </Link>
      
      {/* Estilo adicional para ícones e uma melhor organização */}
      <div className="mt-10">
        <p className="text-sm text-gray-500">Se você precisar de mais ajuda, entre em contato com nossa equipe.</p>
      </div>
    </div>
  );
}

export default NotFound;
