import vertexSystemsImg from '../img/IVIEW.webp';
import vertexCloudSolutionsImg from '../img/lmy_data_academy.webp';
import vertexDataIntegrationImg from '../img/qlink.webp';
import vertexAnalyticsImg from '../img/daita_quest.webp';
import vertexSecureCloudImg from '../img/boogle-bloud.webp';
import vertexAutomationImg from '../img/Sensr.webp';


const companyData = [
  {
    id: 1,
    name: "Vertex Systems",
    description: "Tecnologia avançada para transformar dados em insights estratégicos.",
    image: vertexSystemsImg,
  },
  {
    id: 2,
    name: "Vertex Cloud Solutions",
    description: "Inovação em nuvem para expandir o potencial de inteligência e análise de dados.",
    image: vertexCloudSolutionsImg,
  },
  {
    id: 3,
    name: "Vertex Data Integration",
    description: "Soluções integradas para conectar e otimizar dados de forma unificada.",
    image: vertexDataIntegrationImg,
  },
  {
    id: 4,
    name: "Vertex Analytics",
    description: "Análise de dados precisa para decisões mais inteligentes e ágeis.",
    image: vertexAnalyticsImg,
  },
  {
    id: 5,
    name: "Vertex Secure Cloud",
    description: "Segurança e proteção de dados em nuvem de nível empresarial.",
    image: vertexSecureCloudImg,
  },
  {
    id: 6,
    name: "Vertex Automation",
    description: "Automação de processos para aumentar a eficiência e reduzir custos.",
    image: vertexAutomationImg,
  },

];

export default companyData;
