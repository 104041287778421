import React from 'react';

function FeaturesCards() {
  const features = [
    {
      id: 1,
      title: 'Software on Demand',
      description: 'Somos especialistas em criar e desenvolver sistemas exclusivos para cada empresa.',
      icon: '01',
      bgColor: 'bg-gray-400', // Alterado para tons de cinza
      textColor: 'text-gray-900',
    },
    {
      id: 2,
      title: 'Tecnologia e Inovação',
      description: 'Buscamos a atualização constante de nossa equipe nas tecnologias de ponta.',
      icon: '02',
      bgColor: 'bg-gray-400',
      textColor: 'text-gray-900',
    },
    {
      id: 3,
      title: 'Atendimento',
      description: 'Projetos envolvem pessoas e tecnologia, por isso a importância da Qualidade no Atendimento.',
      icon: '03',
      bgColor: 'bg-gray-400',
      textColor: 'text-gray-900',
    },
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {features.map((feature) => (
          <div 
            key={feature.id} 
            className="relative bg-white p-6 md:p-8 rounded-lg border border-gray-300"
          >
            {/* Círculo com o ícone */}
            <div className={`absolute -top-8 left-1/2 transform -translate-x-1/2 flex items-center justify-center rounded-full h-12 w-12 md:h-16 md:w-16 ${feature.bgColor} ${feature.textColor} font-bold text-lg md:text-xl`}>
              {feature.icon}
            </div>
            
            {/* Conteúdo do Card */}
            <div className="mt-8 text-center">
              <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-3">{feature.title}</h3>
              <p className="text-sm md:text-base text-gray-600">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FeaturesCards;
