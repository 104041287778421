import React from 'react';
import Header from './Header'; // Importar o Header
import backgroundImage from '../img/CAPA.gif'; // Importe a imagem de capa

function PoliticaDePrivacidade() {
  return (
    <>
      {/* Adicionando o Header para consistência */} 
      <Header />

      {/* Ajuste de espaçamento para o conteúdo não ser coberto pelo cabeçalho fixo */}
      <div className="pt-24">
        {/* Seção da Capa */} 
        <section
          className="relative bg-cover bg-center text-white py-12 text-center" // Adicionando padding e centralização
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <h1 className="text-3xl font-bold">Política de Privacidade</h1>
          </div>
        </section>

        {/* Conteúdo da Política de Privacidade */}
        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <div className="bg-white p-8 border-2 border-gray-300 rounded-lg shadow-lg">
              <div className="space-y-6">
                <p className="text-gray-600">
                  Na Vertex Systems, nos comprometemos a proteger a privacidade de nossos usuários e visitantes. Esta política de privacidade explica como coletamos, usamos e protegemos suas informações pessoais.
                </p>

                <h2 className="text-2xl font-semibold text-gray-700">1. Informações que Coletamos</h2>
                <p className="text-gray-600">
                  Podemos coletar informações pessoais, como nome, endereço de e-mail, endereço IP, preferências de uso e interesses, para melhorar a experiência de navegação e oferecer serviços personalizados.
                </p>

                <h2 className="text-2xl font-semibold text-gray-700">2. Uso de Cookies</h2>
                <p className="text-gray-600">
                  Utilizamos cookies para otimizar sua experiência de navegação. Cookies são pequenos arquivos de texto armazenados no seu dispositivo que nos ajudam a lembrar de suas preferências e interesses. Ao continuar utilizando nosso site, você concorda com o uso de cookies.
                </p>

                <h2 className="text-2xl font-semibold text-gray-700">3. Como Usamos Suas Informações</h2>
                <p className="text-gray-600">
                  As informações que coletamos podem ser usadas para:
                </p>
                <ul className="list-disc list-inside text-gray-600">
                  <li>Melhorar a experiência de navegação.</li>
                  <li>Personalizar ofertas e serviços com base em seus interesses.</li>
                  <li>Monitorar o tráfego do site e otimizar seu desempenho.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-gray-700">4. Compartilhamento de Informações</h2>
                <p className="text-gray-600">
                  Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para cumprir com a legislação ou oferecer nossos serviços.
                </p>

                <h2 className="text-2xl font-semibold text-gray-700">5. Seus Direitos</h2>
                <p className="text-gray-600">
                  Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Entre em contato conosco para exercer esses direitos.
                </p>

                <h2 className="text-2xl font-semibold text-gray-700">6. Alterações nesta Política</h2>
                <p className="text-gray-600">
                  Podemos atualizar esta política de privacidade periodicamente. Recomendamos que você a revise regularmente para se manter informado sobre como protegemos suas informações.
                </p>

                <h2 className="text-2xl font-semibold text-gray-700">7. Contato</h2>
                <p className="text-gray-600">
                  Se você tiver dúvidas sobre esta política de privacidade, entre em contato conosco em <a href="mailto:vntecnologialtda@gmail.com" className="text-blue-500 underline">vntecnologialtda@gmail.com</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PoliticaDePrivacidade;
