import React from 'react';
import Slider from 'react-slick';
import { FaQuoteLeft } from 'react-icons/fa';

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  return (
    <section
      className="py-16 bg-cover bg-center text-gray-900"
      style={{
        backgroundImage: `url('https://cdn.svgator.com/images/2022/06/use-svg-as-background-image-particle-strokes.svg')`,
      }}
    >
      <div className="container mx-auto text-center relative z-10">
        <h2 className="text-3xl font-semibold text-white mb-12">Depoimentos</h2>
        <p className="text-lg text-gray-200 mb-12 max-w-lg mx-auto">
          Veja como a Vertex Systems transformou a vida de seus clientes.
        </p>

        <Slider {...settings}>
          {/* Slide 1 */}
          <div className="bg-black bg-opacity-50 rounded-lg p-6 flex items-center justify-between max-w-lg lg:max-w-4xl mx-auto shadow-lg transition-all duration-300">
            <div className="text-left w-full">
              <h3 className="text-2xl lg:text-3xl font-bold text-white mb-4">Marcos Silva</h3>
              <p className="text-white text-base lg:text-lg leading-relaxed mb-6 italic">
                “A Vertex Systems foi essencial no desenvolvimento do nosso sistema de gestão de projetos. O time é muito competente e o atendimento sempre foi rápido e eficiente.”
              </p>
              <p className="text-white text-sm lg:text-base font-semibold">Sistema de Gestão de Projetos – São Paulo, SP</p>
            </div>
            <div className="ml-4 lg:ml-6">
              <FaQuoteLeft className="text-white text-4xl lg:text-6xl opacity-50" />
            </div>
          </div>

          {/* Slide 2 */}
          <div className="bg-black bg-opacity-50 rounded-lg p-6 flex items-center justify-between max-w-lg lg:max-w-4xl mx-auto shadow-lg transition-all duration-300">
            <div className="text-left w-full">
              <h3 className="text-2xl lg:text-3xl font-bold text-white mb-4">Ana Clara</h3>
              <p className="text-white text-base lg:text-lg leading-relaxed mb-6 italic">
                “Estamos muito satisfeitos com o sistema de controle de estoque desenvolvido pela Vertex Systems. Ele atendeu completamente as nossas expectativas.”
              </p>
              <p className="text-white text-sm lg:text-base font-semibold">Sistema de Controle de Estoque – Rio de Janeiro, RJ</p>
            </div>
            <div className="ml-4 lg:ml-6">
              <FaQuoteLeft className="text-white text-4xl lg:text-6xl opacity-50" />
            </div>
          </div>

          {/* Slide 3 */}
          <div className="bg-black bg-opacity-50 rounded-lg p-6 flex items-center justify-between max-w-lg lg:max-w-4xl mx-auto shadow-lg transition-all duration-300">
            <div className="text-left w-full">
              <h3 className="text-2xl lg:text-3xl font-bold text-white mb-4">Roberto Almeida</h3>
              <p className="text-white text-base lg:text-lg leading-relaxed mb-6 italic">
                “O sistema de automação de processos desenvolvido pela Vertex nos ajudou a otimizar nossas operações e reduzir custos. Estamos extremamente satisfeitos com o resultado.”
              </p>
              <p className="text-white text-sm lg:text-base font-semibold">Automação de Processos – Curitiba, PR</p>
            </div>
            <div className="ml-4 lg:ml-6">
              <FaQuoteLeft className="text-white text-4xl lg:text-6xl opacity-50" />
            </div>
          </div>

          {/* Slide 4 */}
          <div className="bg-black bg-opacity-50 rounded-lg p-6 flex items-center justify-between max-w-lg lg:max-w-4xl mx-auto shadow-lg transition-all duration-300">
            <div className="text-left w-full">
              <h3 className="text-2xl lg:text-3xl font-bold text-white mb-4">Carlos Souza</h3>
              <p className="text-white text-base lg:text-lg leading-relaxed mb-6 italic">
                “A Vertex Systems foi essencial no desenvolvimento do nosso sistema de ERP, transformando a maneira como gerenciamos nossas operações.”
              </p>
              <p className="text-white text-sm lg:text-base font-semibold">ERP Corporativo – Belo Horizonte, MG</p>
            </div>
            <div className="ml-4 lg:ml-6">
              <FaQuoteLeft className="text-white text-4xl lg:text-6xl opacity-50" />
            </div>
          </div>

          {/* Slide 5 */}
          <div className="bg-black bg-opacity-50 rounded-lg p-6 flex items-center justify-between max-w-lg lg:max-w-4xl mx-auto shadow-lg transition-all duration-300">
            <div className="text-left w-full">
              <h3 className="text-2xl lg:text-3xl font-bold text-white mb-4">Fernanda Alves</h3>
              <p className="text-white text-base lg:text-lg leading-relaxed mb-6 italic">
                “A Vertex Systems desenvolveu nosso sistema de CRM, proporcionando uma experiência única e melhorando nossa comunicação com os clientes.”
              </p>
              <p className="text-white text-sm lg:text-base font-semibold">Sistema de CRM – Porto Alegre, RS</p>
            </div>
            <div className="ml-4 lg:ml-6">
              <FaQuoteLeft className="text-white text-4xl lg:text-6xl opacity-50" />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
