import React from 'react';
import Header from './Header';
import capaImg from '../img/CAPA.gif';

function QuemSomos() {
    return (
        <>
            <Header />
            <div className="pt-24">
                {/* Cover Section with Background Image */}
                <section
                    className="bg-gray-100 py-12 text-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${capaImg})` }}
                >
                    <div className="container mx-auto px-4">
                        <h1 className="text-3xl font-bold text-gray-100">Quem Somos</h1>
                        <p className="text-lg mt-4 text-gray-100">Conheça mais sobre nossa história e o que nos move na Vertex Systems, diretamente de São Luís - MA, liderados pelo nosso CEO Vanrley Macedo.</p>
                    </div>
                </section>

                {/* About Us Section */}
                <section className="py-12">
                    <div className="container mx-auto space-y-8 px-4 text-left md:text-justify">
                        <div className="text-gray-600 space-y-4">
                            <h3 className="text-2xl font-bold text-gray-700">Nossa Equipe</h3>
                            <p>Somos a Vertex Systems, um time apaixonado por tecnologia e inovação. Fundada em São Luís - MA, nossa empresa nasceu do desejo de transformar ideias em soluções que realmente fazem a diferença para cada um dos nossos clientes. Sob a liderança do Vanrley Macedo, nós nos dedicamos a entender as necessidades dos nossos parceiros e a criar soluções que simplificam, agilizam e inovam.</p>
                            <p>Nosso objetivo é simples: entregar resultados que superem expectativas, sempre com um toque pessoal e uma dedicação que você só encontra aqui na Vertex.</p>
                        </div>

                        <div className="text-gray-600 space-y-4">
                            <h3 className="text-2xl font-bold text-gray-700">Nossa Missão</h3>
                            <p>Queremos ser mais do que uma empresa de tecnologia para você. Nosso compromisso é desenvolver soluções que tragam segurança e eficiência para o seu negócio, sempre adaptadas às suas necessidades. Mais do que entregar um produto, buscamos construir parcerias duradouras e de confiança.</p>
                        </div>
                    </div>
                </section>

                {/* Graphic Section */}
                <section className="bg-gray-100 py-12">
                    <div className="container mx-auto text-center px-4">
                        <h2 className="text-4xl font-bold text-gray-800 mb-6">Nossos Valores e Visão</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            <div className="bg-white p-6 rounded-lg border border-gray-300">
                                <span className="text-7xl font-bold text-gray-600">01</span>
                                <h3 className="text-xl font-bold text-gray-700">Missão</h3>
                                <p className="text-gray-500">Entregar soluções personalizadas e inovadoras, que realmente agreguem valor ao dia a dia das empresas que atendemos.</p>
                            </div>
                            <div className="bg-white p-6 rounded-lg border border-gray-300">
                                <span className="text-7xl font-bold text-gray-600">02</span>
                                <h3 className="text-xl font-bold text-gray-700">Visão</h3>
                                <p className="text-gray-500">Sonhamos grande: ser referência no desenvolvimento de soluções que façam a diferença no mercado e na vida das pessoas que confiam no nosso trabalho.</p>
                            </div>
                            <div className="bg-white p-6 rounded-lg border border-gray-300">
                                <span className="text-7xl font-bold text-gray-600">03</span>
                                <h3 className="text-xl font-bold text-gray-700">Valores</h3>
                                <p className="text-gray-500">Para nós, inovação não é só sobre tecnologia, mas sobre criar conexões reais, agir com transparência e sempre manter o foco na excelência em cada detalhe.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Graph Section */}
                <section className="bg-white py-12">
                    <div className="container mx-auto text-center px-4">
                        <h2 className="text-4xl font-bold text-gray-800 mb-6">Por que nos escolher</h2>
                        <p className="text-lg text-gray-500 mb-12">Somos uma equipe que se importa. Nosso objetivo é ajudar você a crescer, com soluções que realmente funcionam para o seu negócio.</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 items-center">
                            <div className="text-left md:text-justify">
                                <h3 className="text-2xl font-bold text-gray-700 mb-6">Trabalhe com uma empresa que se importa com o seu sucesso</h3>
                                <p className="text-lg text-gray-500 mb-4">Nós sabemos que cada negócio tem suas particularidades. É por isso que criamos soluções sob medida, garantindo que cada detalhe seja pensado para atender às suas necessidades.</p>
                            </div>
                            <div className="space-y-6">
                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-lg font-medium text-gray-500">Tecnologia</span>
                                        <span className="text-lg font-medium text-gray-500">100%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-4">
                                        <div className="bg-gray-600 h-4 rounded-full" style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-lg font-medium text-gray-500">Qualificação</span>
                                        <span className="text-lg font-medium text-gray-500">100%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-4">
                                        <div className="bg-gray-600 h-4 rounded-full" style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="text-lg font-medium text-gray-500">Satisfação do cliente</span>
                                        <span className="text-lg font-medium text-gray-500">100%</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-4">
                                        <div className="bg-gray-600 h-4 rounded-full" style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default QuemSomos;
