// src/components/CookiesConsent.js
import React, { useState, useEffect } from 'react';

function CookiesConsent() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookiesAccepted');
    if (consent) {
      setCookiesAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', true);
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) {
    return null; // Se o usuário já aceitou os cookies, não mostrar mais o banner
  }

  return (
    <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white py-4 px-6 flex justify-between items-center z-50">
      <p className="text-sm">
        Utilizamos cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa{' '}
        <a href="/politica-de-privacidade" className="underline text-blue-400">política de cookies</a>.
      </p>
      <button 
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg ml-4"
        onClick={acceptCookies}
      >
        Aceitar Cookies
      </button>
    </div>
  );
}

export default CookiesConsent;
