import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../img/vanrley.svg';
import Loading from './Loading';
import menuIcon from '../img/menu-svgrepo-com.svg';  // Ícone de menu hambúrguer
import closeIcon from '../img/close-svgrepo-com.svg'; // Ícone de fechar

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [location]);

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'Nossas Soluções', path: '/nossas-solucoes' },
    { name: 'Quem Somos', path: '/quem-somos' },
    { name: 'Tecnologias', path: '/tecnologias' },
    { name: 'Projetos', path: '/projetos' },
    { name: 'Fale Conosco', path: '/fale-conosco' },
    { name: 'Certificados', path: '/certificados' } // Novo link para Certificados
  ];

  return (
    <header className="w-full fixed top-0 z-50">
      {loading && <Loading />}

      {/* Barra superior para informações de contato */}
      <div className="hidden md:block bg-blue-800 py-2 text-white">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="flex items-center space-x-6">
            <div className="flex items-center space-x-2">
              <FaPhoneAlt className="text-white" />
              <span className="text-sm">Contato: (98) 97018-0089</span>
            </div>
            <span className="text-sm">|</span>
            <div className="flex items-center space-x-2">
              <FaEnvelope className="text-white" />
              <span className="text-sm">vntecnologialtda@gmail.com</span>
            </div>
            <span className="text-sm">|</span>
            <div className="flex items-center space-x-2">
              <FaClock className="text-white" />
              <span className="text-sm">Seg - Sab 9:00 - 18:00 / Domingo - FECHADO</span>
            </div>
          </div>
        </div>
      </div>

      {/* Barra de navegação principal */}
      <div className="bg-white shadow-md py-4">
        <div className="container mx-auto flex justify-between items-center px-4">
          {/* Logo */}
          <div className="flex items-center">
            <img src={logo} alt="Logo Vertex Systems" className="h-12 mr-3" />
          </div>

          {/* Botão do menu mobile com rotação */}
          <button
            className="md:hidden block text-gray-700 focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              src={isOpen ? closeIcon : menuIcon}
              alt="Menu"
              className={`w-8 h-8 transition-transform duration-300 ${isOpen ? 'rotate-90' : ''}`}
            />
          </button>

          {/* Navegação para Desktop */}
          <nav className="hidden md:flex space-x-6">
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                to={link.path}
                className={({ isActive }) =>
                  isActive ? 'nav-link-active' : 'nav-link'
                }
                onClick={() => setLoading(true)}
              >
                {link.name}
              </NavLink>
            ))}
          </nav>

          {/* Telefone destacado */}
          <a href="tel:+5598970180089" className="hidden md:flex items-center space-x-4">
            <div className="flex items-center space-x-2 bg-green-500 px-4 py-2 rounded-lg text-white">
              <FaPhoneAlt className="text-white" />
              <span className="text-lg font-semibold">(98) 97018-0089</span>
            </div>
          </a>

          {/* Menu mobile deslizante da esquerda */}
          <div className={`fixed top-0 left-0 w-64 h-full bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
            <nav className="flex flex-col space-y-4 p-6">
              {navLinks.map((link, index) => (
                <NavLink
                  key={index}
                  to={link.path}
                  className={({ isActive }) =>
                    isActive ? 'nav-link-active' : 'nav-link'
                  }
                  onClick={() => {
                    setLoading(true);
                    setIsOpen(false);
                  }}
                >
                  {link.name}
                </NavLink>
              ))}
              {/* Telefone para mobile com redirecionamento */}
              <a href="tel:+5598970180089" className="flex items-center space-x-2 bg-green-500 px-4 py-2 rounded-lg text-white">
                <FaPhoneAlt className="text-white" />
                <span className="text-lg font-semibold">(98) 97018-0089</span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
