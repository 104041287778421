import React from 'react';
import { FaCog } from 'react-icons/fa'; // Usando ícone de engrenagem

function Loading() {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="relative">
        {/* Efeito de expansão ao redor */} 
        <div className="absolute inset-0 animate-ping rounded-full bg-green-500 opacity-30"></div> 

        {/* Ícone de engrenagem com rotação */} 
        <FaCog className="text-green-500 text-6xl animate-spin" /> 
      </div>
    </div>
  );
}

export default Loading;
