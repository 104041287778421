import React from 'react';
import {createBrowserRouter,RouterProvider} from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesCards from './components/FeaturesCards';
import Highlights from './components/Highlights';
import DevelopmentProcess from './components/DevelopmentProcess';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import CompanySlider from './components/CompanySlider';
import NossasSolucoes from './components/NossasSolucoes';
import QuemSomos from './components/QuemSomos';
import Projects from './components/Projects';
import FaleConosco from './components/FaleConosco';
import Tecnologias from './components/Tecnologias';
import FAQ from './components/FAQ';
import CookiesConsent from './components/CookieConsent';
import PoliticaDePrivacidade from './components/PoliticaDePrivacidade';
import QuemSouEu from './components/QuemSouEu';
import WhatWeDeliver from './components/WhatWeDeliver';
import NotFound from './components/NotFound';
import ScrollToTopButton from './components/ScrollToTopButton';
import Certificados from './components/Certificados';  
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <HeroSection />
                <WhatWeDeliver />
                <FeaturesCards />
                <CompanySlider />
                <Highlights />
                <DevelopmentProcess />
                <QuemSouEu />
                <Testimonials />
                <FAQ />
              </>
            } 
          />
          <Route path="/nossas-solucoes" element={<NossasSolucoes />} />
          <Route path="/quem-somos" element={<QuemSomos />} />
          <Route path="/fale-conosco" element={<FaleConosco />} />
          <Route path="/tecnologias" element={<Tecnologias />} />
          <Route path="/projetos" element={<Projects />} /> 
          <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
          <Route path="/certificados" element={<Certificados />} /> {/* Rota para a nova página */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <CookiesConsent />
        <ScrollToTopButton /> {/* Botão de "Voltar ao Topo" */}
      </div>
    </Router>
  );
}

export default App;
 