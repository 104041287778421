import React from 'react';
import Slider from 'react-slick';
import companyData from '../data/CompanyData';

function CompanySlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 6000, // Velocidade mais lenta para rotação contínua
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Rola continuamente, sem pausas
    cssEase: 'linear', // Rolagem suave e contínua
    pauseOnHover: false, // Desativa pausa ao passar o mouse
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5, // Mostra 1.5 slides para cards mais largos
        },
      },
    ],
  };

  const overlayColors = [
    'from-green-800 to-transparent', 
    'from-red-800 to-transparent', 
    'from-orange-800 to-transparent', 
  ];

  return (
    <section className="py-8 bg-gray-900 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Nossas Soluções</h2>
        <Slider {...settings}>
          {companyData.map((company, index) => (
            <div key={company.id} className="p-4">
              <div
                className="relative rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105"
                style={{
                  height: '400px', // Ajuste de altura para menos estreito no mobile
                  backgroundImage: `url(${company.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {/* Efeito de cor de fundo */}
                <div className={`absolute inset-0 bg-gradient-to-t ${overlayColors[index % overlayColors.length]} opacity-75 hover:opacity-90 transition-opacity duration-300`}></div>
                
                {/* Conteúdo do card */}
                <div className="relative z-10 flex flex-col items-center justify-center h-full p-6">
                  <h3 className="text-2xl font-semibold text-white mb-4">
                    {company.name}
                  </h3>
                  <p className="mt-2 text-lg text-gray-200">
                    {company.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default CompanySlider;
