import React, { useState } from 'react';
import capaImg from '../img/CAPA.gif'; // Imagem da capa
import Header from './Header';

function FaleConosco() {
  const [formData, setFormData] = useState({
    name: '',
    subject: ''
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const generateTicketNumber = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const ticketNumber = generateTicketNumber();
    const domain = window.location.hostname;
    const whatsappNumber = '5598970180089';
    const message = `Novo Contato do Site%0A Ticket: #${ticketNumber}%0A Nome: ${formData.name}%0A Assunto: ${formData.subject}%0A Site: ${domain}`;
    const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

    setTimeout(() => {
      window.open(whatsappURL, '_blank');
      setSuccess(true);
      setFormData({ name: '', subject: '' });
      setLoading(false);
    }, 3000);
  };

  return (
    <>
      <Header />
      <div className="pt-24 overflow-hidden">
        <section 
          className="bg-gray-300 py-12 text-center bg-cover bg-center" 
          style={{ backgroundImage: `url(${capaImg})` }}
        >
          <div className="container mx-auto">
            <h1 className="text-3xl font-bold text-gray-100">Fale Conosco</h1>
            <p className="text-lg mt-4 text-gray-100">Entre em contato e responderemos o mais breve possível.</p>
          </div>
        </section>

        <div className="container mx-auto py-12">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Envie sua Mensagem</h2>
          
          {success && (
            <div className="mb-6 p-4 bg-gray-100 border-l-4 border-gray-400 text-gray-700">
              Sua mensagem foi enviada com sucesso! Vamos responder via WhatsApp.
            </div>
          )}

          {loading && (
            <div className="mb-6 p-4 bg-gray-200 border-l-4 border-gray-400 text-gray-700">
              Redirecionando para o WhatsApp...
            </div>
          )}

          <form onSubmit={handleSubmit} className="mx-auto max-w-4xl p-6 bg-gray-50 shadow-md rounded-lg border border-gray-200">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700">Nome</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  className="w-full border rounded-lg px-3 py-2 text-gray-700"
                  value={formData.name} 
                  onChange={handleChange} 
                  required 
                />
              </div>
              <div className="mb-4 col-span-2">
                <label htmlFor="subject" className="block text-gray-700">Assunto</label>
                <textarea
                  id="subject"
                  name="subject"
                  className="w-full border rounded-lg px-3 py-2 h-40 text-gray-700"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
            <button 
              type="submit" 
              className="bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 transition duration-300 w-full"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default FaleConosco;
