import React from 'react';
import Header from './Header';
import { FaCheckCircle, FaCode, FaRocket } from 'react-icons/fa';
import capaImg from '../img/CAPA.gif';

function NossasSolucoes() {
    return (
        <>
            <Header />

            {/* Ajuste de espaçamento para o conteúdo não ser coberto pelo cabeçalho fixo */}
            <div className="pt-24">
                {/* Seção de Capa com Imagem de Fundo */}
                <section
                    className="bg-gray-200 py-12 text-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${capaImg})` }}
                >
                    <div className="container mx-auto">
                        <h1 className="text-3xl font-bold text-gray-100">Nossas Soluções</h1>
                        <p className="text-lg mt-4 text-gray-100">Descubra as soluções que oferecemos para transformar o seu negócio.</p>
                    </div>
                </section>

                {/* Seção de Soluções em Lista */}
                <section className="py-12">
                    <div className="container mx-auto space-y-8">
                        {/* Lista de Soluções */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <div className="bg-white p-6 rounded-lg border border-gray-300 flex items-start">
                                <FaCheckCircle className="text-6xl text-gray-500 mr-4" />
                                <div>
                                    <h3 className="text-2xl font-bold text-gray-700 mb-2">Fábrica de Software</h3>
                                    <p className="text-gray-600">Desenvolvemos soluções sob medida para atender às necessidades da sua empresa.</p>
                                </div>
                            </div>
                            <div className="bg-white p-6 rounded-lg border border-gray-300 flex items-start">
                                <FaCode className="text-6xl text-gray-500 mr-4" />
                                <div>
                                    <h3 className="text-2xl font-bold text-gray-700 mb-2">Squad Dedicado</h3>
                                    <p className="text-gray-600">Equipe qualificada para garantir o sucesso do seu projeto, atuando com alta performance.</p>
                                </div>
                            </div>
                            <div className="bg-white p-6 rounded-lg border border-gray-300 flex items-start">
                                <FaRocket className="text-6xl text-gray-500 mr-4" />
                                <div>
                                    <h3 className="text-2xl font-bold text-gray-700 mb-2">Suporte e Hospedagem</h3>
                                    <p className="text-gray-600">Oferecemos suporte técnico e hospedagem especializada para o seu sistema.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Diferenciais */}
                <section className="py-12">
                    <div className="container mx-auto text-center">
                        <h2 className="text-3xl font-bold text-gray-800 mb-6">Nossos Diferenciais</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {/* Cartões de Diferenciais com números grandes */}
                            <div className="bg-white p-6 rounded-lg border border-gray-300">
                                <div className="flex items-center justify-center mb-4">
                                    <span className="text-7xl font-bold text-gray-500">01</span>
                                </div>
                                <h3 className="text-xl font-bold text-gray-700">Garantia</h3>
                                <p className="text-gray-600 text-sm">Oferecemos 60 dias de garantia para possíveis bugs após a entrega.</p>
                            </div>
                            <div className="bg-white p-6 rounded-lg border border-gray-300">
                                <div className="flex items-center justify-center mb-4">
                                    <span className="text-7xl font-bold text-gray-500">02</span>
                                </div>
                                <h3 className="text-xl font-bold text-gray-700">Código Fonte</h3>
                                <p className="text-gray-600 text-sm">Liberdade total com a entrega do código fonte do seu projeto.</p>
                            </div>
                            <div className="bg-white p-6 rounded-lg border border-gray-300">
                                <div className="flex items-center justify-center mb-4">
                                    <span className="text-7xl font-bold text-gray-500">03</span>
                                </div>
                                <h3 className="text-xl font-bold text-gray-700">Melhorias Contínuas</h3>
                                <p className="text-gray-600 text-sm">Nos comprometemos a evoluir continuamente sua solução com melhorias.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default NossasSolucoes;
