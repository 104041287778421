import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

function FAQ() {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: 'Quais tipos de sistemas vocês desenvolvem?',
      answer:
        'Desenvolvemos sistemas web, aplicativos mobile e soluções sob medida para automatizar e melhorar processos internos de empresas.',
    },
    {
      question: 'Quais formas de pagamento vocês aceitam?',
      answer:
        'Aceitamos PIX, boleto bancário e cartão de crédito. Para pagamentos no crédito, há a possibilidade de parcelamento com juros.',
    },
    {
      question: 'Vocês oferecem suporte após a entrega do sistema?',
      answer:
        'Sim, oferecemos suporte contínuo e planos de manutenção para garantir que seu sistema continue funcionando perfeitamente.',
    },
    {
      question: 'Vocês trabalham com sistemas já existentes?',
      answer:
        'Sim, podemos trabalhar em sistemas já existentes, fazendo melhorias, atualizações ou integrações com novas funcionalidades.',
    },
    {
      question: 'Como é o processo de desenvolvimento de um sistema?',
      answer:
        'Nosso processo de desenvolvimento inclui levantamento de requisitos, prototipagem, desenvolvimento, testes e implementação. Trabalhamos de forma colaborativa com o cliente durante todas as fases.',
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4 md:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
          Perguntas Frequentes
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white border border-gray-300 rounded-lg shadow-md p-4"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleQuestion(index)}
              >
                <h3 className="text-xl font-semibold text-gray-800">
                  {faq.question}
                </h3>
                <div>
                  {openQuestion === index ? (
                    <FaMinus className="text-gray-600" />
                  ) : (
                    <FaPlus className="text-gray-600" />
                  )}
                </div>
              </div>
              {openQuestion === index && (
                <p className="mt-4 text-gray-600 text-lg">
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
