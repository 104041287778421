import React from 'react';
import { FaPencilAlt, FaBolt, FaTachometerAlt, FaHeadset, FaShieldAlt, FaCogs } from 'react-icons/fa';

function WhatWeDeliver() {
  return (
    <section className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">O que Entregamos</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Design Premium */}
          <div className="bg-white p-6 rounded-lg border border-gray-200">
            <FaPencilAlt className="text-4xl text-gray-600 mb-4 mx-auto" />
            <h3 className="text-xl font-bold text-gray-700">Design Premium</h3>
            <p className="text-gray-600 text-sm">UI/UX elaborados cuidadosamente de forma personalizada para a sua marca.</p>
          </div>

          {/* Segurança de Dados */}
          <div className="bg-white p-6 rounded-lg border border-gray-200">
            <FaShieldAlt className="text-4xl text-gray-600 mb-4 mx-auto" />
            <h3 className="text-xl font-bold text-gray-700">Segurança de Dados</h3>
            <p className="text-gray-600 text-sm">Implementamos as melhores práticas de segurança para proteger os dados do seu negócio e clientes.</p>
          </div>

          {/* Custo Benefício */}
          <div className="bg-white p-6 rounded-lg border border-gray-200">
            <FaBolt className="text-4xl text-gray-600 mb-4 mx-auto" />
            <h3 className="text-xl font-bold text-gray-700">Custo Benefício</h3>
            <p className="text-gray-600 text-sm">Valores acessíveis, proporcionando um retorno excepcional no investimento do seu negócio.</p>
          </div>

          {/* Velocidade */}
          <div className="bg-white p-6 rounded-lg border border-gray-200">
            <FaTachometerAlt className="text-4xl text-gray-600 mb-4 mx-auto" />
            <h3 className="text-xl font-bold text-gray-700">Velocidade</h3>
            <p className="text-gray-600 text-sm">Sites que carregam rapidamente, aumentando a sua taxa de conversão.</p>
          </div>

          {/* Suporte Premium */}
          <div className="bg-white p-6 rounded-lg border border-gray-200">
            <FaHeadset className="text-4xl text-gray-600 mb-4 mx-auto" />
            <h3 className="text-xl font-bold text-gray-700">Suporte Premium</h3>
            <p className="text-gray-600 text-sm">Suporte e acompanhamento personalizado para garantir o funcionamento do seu site.</p>
          </div>

          {/* Automação de Processos */}
          <div className="bg-white p-6 rounded-lg border border-gray-200">
            <FaCogs className="text-4xl text-gray-600 mb-4 mx-auto" />
            <h3 className="text-xl font-bold text-gray-700">Automação de Processos</h3>
            <p className="text-gray-600 text-sm">Automatizamos processos para aumentar a eficiência e reduzir custos operacionais.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatWeDeliver;
